import { US_LOCALE } from '~/assets/strings';

export function commaNumber(value: string | number | undefined, option?: Intl.NumberFormatOptions) {
  return Number(value).toLocaleString(US_LOCALE, {
    maximumFractionDigits: 20,
    ...option,
  });
}

export function currency(value: string | number | undefined, option?: Intl.NumberFormatOptions) {
  if (value === undefined || isNaN(Number(value))) {
    return '$0.00';
  }

  const num = Number(value);
  const isNegative = num < 0;

  const formattedValue = Math.abs(num).toLocaleString(US_LOCALE, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...option,
  });

  return isNegative ? `-${formattedValue}` : formattedValue;
}

export const currencyToNumber = (formattedCurrency: string): number => {
  const cleanedCurrency = formattedCurrency.replace(/[^0-9.-]/g, '');
  const numericValue = parseFloat(cleanedCurrency);

  return numericValue;
};
